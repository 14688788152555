/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import ContractFilterBar from "../../../components/Selects/ContractFilterBar";
import TechControl from "../components/TechControl";
import * as clientApi from "../../../services/api/client";
import * as contractApi from "../../../services/api/contract";
import * as concreteApi from "../../../services/api/concrete";
import CPsByPeriod from "./CPsByPeriod";
import { HeaderTitle } from "../../../components/Headers";
import { TableList, useData, DataTable } from "../../../components/Table";
import { columnsTB, useColumnsSeries } from "./columns";
import { LoadingComponent } from "../../../components/Loader";
import { ListButtonRow, ButtonDelete } from "../../../components/Buttons";
import { PostSeries, PutSeries } from "./Series/Form";
import { PostControl, PutControl } from "./Control";
import TestingBody from "./TestingBoby";
import { ReportRow, ReportButton } from "../../../components/Report";
import ReportBar from "./ReportBar";
import SeriesAnalyse from "./SeriesAnalyse";
import { EditTestAccessPermission } from "../../../components/Permissions";
import { ContainerFluid } from "../../../components/Container";
import Summary from "./Summary";
import { useUser } from "src/context/admin/user";

const PAGE_TITLE = "Controle de concreto";

const Concrete = () => {
  const [user] = useUser();
  const [control, setControl] = useState<TConcreteControl>();
  const [contractId, setContractId] = useState<number>();
  const [loadingControl, setLoadingControl] = useState<boolean>(false);
  const [seriesData, setSeriesData] = useState<TPage<TConcreteSeries>>();
  const [loadingSeries, setLoadingSeries] = useState<boolean>(true);
  const series = useData<TConcreteSeries>();
  const [cps, setCPs] = useState<TConcreteTestingBody[]>([]);
  const [loadingCPs, setLoadingCPs] = useState<boolean>(false);
  const [dbClickCP, setDbClickCP] = useState<boolean>(false);
  const cp = useData<TConcreteTestingBody>();
  const seriesColumns = useColumnsSeries();

  useEffect(() => {
    getSeries(1, 100);
  }, [control]);

  const getSeries = (page: number, pageSize: number) => {
    if (control !== undefined && control.id !== undefined) {
      setLoadingSeries(true);
      concreteApi
        .getAllSeries(
          control.id,
          "id,number,place,molding_date,fck,boletim,verified,fck_reached,approved,has_cp_missing_break,has_error_in_test",
          page,
          pageSize,
        )
        .then(({ data }) => setSeriesData(data))
        .finally(() => setLoadingSeries(false));
    } else {
      setSeriesData(undefined);
      setCPs([]);
    }
  };

  useEffect(() => {
    const selected = series.selected.item;
    if (selected !== undefined && selected.id !== undefined) {
      setLoadingCPs(true);
      concreteApi
        .getCPs(selected.id, "")
        .then(({ data }) => setCPs(data))
        .finally(() => setLoadingCPs(false));
    } else {
      setCPs([]);
    }
  }, [series.selected.item]);

  const getClients = useCallback(() => {
    return clientApi.getClients("concrete", "1", "id,name");
  }, []);

  const getContracts = useCallback((clientId: number) => {
    return contractApi.getContracts("", `${clientId}`, "", "", "concrete", "1");
  }, []);

  const getControl = useCallback(() => {
    if (contractId) {
      setLoadingControl(true);
      concreteApi
        .getControl(contractId)
        .then(({ data }) => {
          setControl(data[0]);
        })
        .finally(() => {
          setLoadingControl(false);
        });
    }
  }, [contractId]);

  useEffect(() => {
    if (contractId !== undefined && contractId !== null) {
      getControl();
    } else {
      setControl(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractId]);

  const onDeleteSeries = () => {
    if (series.selected.item && series.selected.item.id !== undefined) {
      return concreteApi
        .deleteSeries(series.selected.item.id)
        .then((res) => {
          series.deleted.set(series.selected.item);
          setCPs([]);
          return Promise.resolve(res);
        })
        .catch((res) => {
          return Promise.reject(res);
        });
    }

    return Promise.reject();
  };

  const renderSeries = () => {
    return (
      <div className="text-left">
        <HeaderTitle title="Séries" />
        <DataTable
          columns={seriesColumns}
          data={seriesData?.results || []}
          progressPending={loadingSeries}
          pagination
          paginationTotalRows={seriesData?.count || 0}
          comparator={(a, b) => a.number! - b.number!}
          onSelect={series.selected.set}
          keyExtractor={(item: TConcreteSeries) => `${item.id}`}
          onChangePage={getSeries}
          updated={series.updated.item}
          deleted={series.deleted.item}
          created={series.created.item}
        />
        <ListButtonRow
          marginTop="mt-2"
          buttonsLeft={
            <>
              <PostSeries control={control!} onCreated={series.created.set} />
              <PutSeries
                control={control!}
                item={series.selected.item}
                onUpdated={series.updated.set}
              />
              <SeriesAnalyse
                item={series.selected.item}
                control={control!}
                onUpdated={series.updated.set}
              />
            </>
          }
          buttonsRight={
            <EditTestAccessPermission>
              <ButtonDelete
                disabled={!series.selected.item}
                onClick={onDeleteSeries}
              />
            </EditTestAccessPermission>
          }
        />
      </div>
    );
  };

  const renderCPs = () => {
    return (
      <>
        <HeaderTitle title="Corpos de prova" />
        <LoadingComponent loading={loadingCPs}>
          <TableList
            tableId="cps"
            data={cps}
            columns={columnsTB}
            created={cp.created.item}
            selected={cp.selected.set}
            updated={cp.updated.item}
            deleted={cp.deleted.item}
            dbClick={(item) => {
              cp.selected.set(item);
              setDbClickCP(true);
              setDbClickCP(false);
            }}
            searching={false}
          />
        </LoadingComponent>
        <ListButtonRow
          marginTop="mt-2"
          buttonsLeft={
            <EditTestAccessPermission>
              <TestingBody
                updatedItem={cp.updated.set}
                showModal={dbClickCP}
                itemToEdit={cp.selected.item}
              />
            </EditTestAccessPermission>
          }
        />
      </>
    );
  };

  const renderControl = () => {
    return (
      <>
        <ReportRow>
          <ReportBar contractId={contractId} controlId={control?.id} />
        </ReportRow>
        <Card className="filter-bar">
          <CardBody>
            <Row>
              <Col sm={5}>{renderSeries()}</Col>
              <Col sm={7}>{renderCPs()}</Col>
            </Row>
          </CardBody>
        </Card>
        <EditTestAccessPermission>
          <>{contractId && <Summary contractId={contractId} />}</>
        </EditTestAccessPermission>
      </>
    );
  };

  const renderBeforeControl = () => {
    return (
      <EditTestAccessPermission>
        <CPsByPeriod />
      </EditTestAccessPermission>
    );
  };

  const insertControl = () => {
    if (contractId !== undefined) {
      return <PostControl onCreated={setControl} contractId={contractId} />;
    }

    return null;
  };

  return (
    <ContainerFluid title={PAGE_TITLE}>
      <>
        <Row>
          <Col sm={8}>
            <ContractFilterBar
              clientRequest={getClients}
              contractRequest={getContracts}
              onContractChange={setContractId}
            />
          </Col>
          <Col sm={4} className="text-right">
            <ListButtonRow
              buttonsRight={
                control !== undefined && (
                  <>
                    <EditTestAccessPermission>
                      <PutControl control={control} onUpdated={setControl} />
                    </EditTestAccessPermission>
                    {user.data?.email !== "labinfrafdv2@infratech.eng.br" && (
                      <ReportButton color="white" />
                    )}
                  </>
                )
              }
            />
          </Col>
        </Row>
        <TechControl
          contract={contractId}
          control={control}
          renderControl={renderControl}
          loadingControl={loadingControl}
          insertControl={insertControl}
          renderBeforeControl={renderBeforeControl}
        />
      </>
    </ContainerFluid>
  );
};

export default Concrete;
