import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import {
  InputCheckbox,
  InputColumn,
  InputDate,
  InputSelect,
} from "src/components/Inputs";
import Select from "react-select";
import { Formik, Field, Form } from "formik";
import { getDistinctPlaces } from "src/services/api/graute";
import { ButtonOption } from "src/components/Buttons";
import { BASE_REPORT_URL } from "src/services/api/api";
import { fastToken } from "src/services/api/core";
import {
  grauteFlexionReport,
  grauteSeriesReport,
  grauteTraceReport,
  grauteTraceReportV2,
} from "src/services/reports";
import { useParams } from "react-router-dom";

const reportType = {
  LOCAL_TRACE: 1,
  GERAL_TRACE: 2,
  LOCAL_PRESSURE: 3,
  GERAL_PRESSURE: 4,
  CPS_TO_PRESS: 5,
  LOCAL_FLEXION: 6,
  NEW_LOCAL_TRACE: 7,
};

interface Props {
  controlId?: number;
  contractId?: number;
}

type Places = {
  tower: string;
  join: string;
  link: string;
  manufacturer: string;
};

const ReportBar: React.FC<Props> = ({ controlId, contractId }) => {
  const { appSlug } = useParams<{ appSlug: string }>();
  const [places, setPlaces] = useState<Places[]>([]);

  useEffect(() => {
    if (controlId !== undefined) {
      getDistinctPlaces(controlId, "", "").then(({ data }) => {
        setPlaces(data.towers_joins);
      });
    }
  }, [controlId]);

  const towersOptions = () => {
    const items = Array.from(new Set(places.map((item) => item.tower)));

    return items.map((item) => ({
      value: item,
      label: item,
    }));
  };

  const joinsOptions = () => {
    const items = Array.from(new Set(places.map((item) => item.join)));

    return items.map((item) => ({
      value: item,
      label: item,
    }));
  };

  const linksOptions = () => {
    const items = Array.from(new Set(places.map((item) => item.link)));

    return items.map((item) => ({
      value: item,
      label: item,
    }));
  };

  const providersOptions = () => {
    const items = Array.from(new Set(places.map((item) => item.manufacturer)));

    return items.map((item) => ({
      value: item,
      label: item,
    }));
  };

  const onSubmit = async (values: any) => {
    let url = "#";
    const args =
      `?date_start=${values.startDate}` +
      `&date_end=${values.endDate}` +
      `&towers=${values.towers.join(",")}` +
      `&joins=${values.joins.join(",")}` +
      `&links=${values.links.join(",")}` +
      `&contract=${controlId}` +
      `&providers=${values.providers.join(",")}` +
      `&report_type=${values.type}`;

    if (values.type && contractId) {
      if (values.type === reportType.LOCAL_TRACE) {
        fastToken().then(({ data }) => {
          grauteTraceReport(
            contractId,
            appSlug,
            data.access,
            values.towers,
            values.joins,
            values.links,
            values.startDate,
            values.endDate,
            values.providers,
            values.collected,
          );
        });
      } else if (values.type === reportType.LOCAL_PRESSURE) {
        fastToken().then(({ data }) => {
          grauteSeriesReport(
            contractId,
            appSlug,
            data.access,
            values.towers,
            values.joins,
          );
        });
      } else if (values.type === reportType.LOCAL_FLEXION) {
        fastToken().then(({ data }) => {
          grauteFlexionReport(
            contractId,
            appSlug,
            data.access,
            values.towers,
            values.joins,
          );
        });
      } else if (values.type === reportType.NEW_LOCAL_TRACE) {
        const { data } = await fastToken();
        grauteTraceReportV2(
          controlId!,
          appSlug,
          data.access,
          values.towers,
          values.joins,
          values.links,
          values.startDate,
          values.endDate,
          values.providers,
          values.collected,
        );
      } else {
        url = `${BASE_REPORT_URL}graute/reports/${args}`;
        window.open(url, "_blank");
      }
    }
  };

  return (
    <Card>
      <CardBody>
        <Formik
          onSubmit={onSubmit}
          enableReinitialize
          initialValues={{
            towers: [],
            joins: [],
            links: [],
            providers: [],
            startDate: "",
            endDate: "",
            type: undefined,
            collected: false,
          }}
          render={({ values, setFieldValue }) => (
            <Form>
              <Row>
                <Col sm={2}>
                  <InputColumn label="Torres*">
                    <Select
                      isMulti
                      styles={{
                        container: () => ({
                          width: "100%",
                          padding: "0",
                        }),
                      }}
                      options={towersOptions()}
                      onChange={(values: any) =>
                        setFieldValue(
                          "towers",
                          values.map((item: any) => item.value),
                        )
                      }
                    />
                  </InputColumn>
                  <InputColumn>
                    <InputCheckbox
                      name="stressChart"
                      label="Apenas coletados"
                      checked={values.collected}
                      onClick={() =>
                        setFieldValue("collected", !values.collected)
                      }
                    />
                  </InputColumn>
                </Col>
                <Col sm={2}>
                  <InputColumn label="Local">
                    <Select
                      isMulti
                      styles={{
                        container: () => ({
                          width: "100%",
                          padding: "0",
                        }),
                      }}
                      options={joinsOptions()}
                      onChange={(values: any) =>
                        setFieldValue(
                          "joins",
                          values.map((item: any) => item.value),
                        )
                      }
                      isDisabled={values.towers.length === 0}
                    />
                    <small>Ao menos um local deve ser selecionado</small>
                  </InputColumn>
                </Col>
                <Col sm={2}>
                  <InputColumn label="Junta">
                    <Select
                      isMulti
                      styles={{
                        container: () => ({
                          width: "100%",
                          padding: "0",
                        }),
                      }}
                      options={linksOptions()}
                      onChange={(values: any) =>
                        setFieldValue(
                          "links",
                          values.map((item: any) => item.value),
                        )
                      }
                      isDisabled={values.joins.length === 0}
                    />
                  </InputColumn>
                </Col>
                <Col sm={2}>
                  <InputColumn label="Fornecedor">
                    <Select
                      isMulti
                      styles={{
                        container: () => ({
                          width: "100%",
                          padding: "0",
                        }),
                      }}
                      options={providersOptions()}
                      onChange={(values: any) =>
                        setFieldValue(
                          "providers",
                          values.map((item: any) => item.value),
                        )
                      }
                    />
                    <small>Ao menos uma torre deve ser selecionada</small>
                  </InputColumn>
                </Col>
                <Col sm={2}>
                  <InputColumn label="Período">
                    <Field name="startDate" component={InputDate} />
                  </InputColumn>
                  <InputColumn>
                    <Field name="endDate" component={InputDate} />
                  </InputColumn>
                </Col>
                <Col sm={2}>
                  <InputColumn label="Relatório">
                    <InputSelect
                      onClick={(value: any) => {
                        setFieldValue("type", value);
                      }}
                      options={[
                        {
                          value: reportType.NEW_LOCAL_TRACE,
                          label: "Traços por local (novo)",
                        },
                        // {
                        //   value: reportType.LOCAL_TRACE,
                        //   label: "Traços por local",
                        // },
                        {
                          value: reportType.LOCAL_PRESSURE,
                          label: "Compressão por local",
                        },
                        {
                          value: reportType.LOCAL_FLEXION,
                          label: "Flexão por local",
                        },
                        {
                          value: reportType.CPS_TO_PRESS,
                          label: "CPs a romper",
                        },
                      ]}
                    />
                  </InputColumn>
                  <ButtonOption type="submit" className="btn-block">
                    Gerar relatório
                  </ButtonOption>
                </Col>
              </Row>
            </Form>
          )}
        />
      </CardBody>
    </Card>
  );
};

export default ReportBar;
