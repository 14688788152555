import api from "./api/api";
const BASE_URL = "https://us-central1-gm6-hq.cloudfunctions.net/report/";
// const BASE_URL_V2 = "http://localhost:3001";
const BASE_URL_V2 = "https://go.gm6.com.br";

function parseParams(
  params: { [key: string]: any },
  arrayFormat: boolean = false,
) {
  const stringParams = Object.keys(params)
    .map((key) => {
      const paramsValues = params[key];
      if (Array.isArray(paramsValues) && arrayFormat) {
        return paramsValues
          .map((param) => `${key}=${encodeURIComponent(param)}`)
          .join("&");
      } else {
        return `${key}=${encodeURIComponent(params[key] || "")}`;
      }
    })
    .join("&");

  if (stringParams.length > 0) {
    return `?${stringParams}`;
  }

  return "";
}

export function stressReportV2(
  contractId: number,
  startDate: string,
  endDate: string,
  places: string[],
  optionalPlaces: string[],
  series: string,
  application: string,
  token: string,
  attachChart: boolean,
) {
  const params = {
    molding_start: startDate,
    molding_end: endDate,
    places: places.join(","),
    optional_places: optionalPlaces.join(","),
    contract: contractId,
    attach_chart: attachChart,
    series: series,
    token: token,
    application: application,
  };

  const endpoint = `${BASE_URL_V2}/stress${parseParams(params)}`;
  window.open(endpoint, "_blank");
}

export function stressReport(
  contractId: number,
  startDate: string,
  endDate: string,
  places: string[],
  optionalPlaces: string[],
  series: string,
  application: string,
  token: string,
  attachChart: boolean,
) {
  const params = {
    molding_start: startDate,
    molding_end: endDate,
    places: places.join(","),
    optional_places: optionalPlaces.join(","),
    contract: contractId,
    attach_chart: attachChart,
    series: series,
    token: token,
    application: application,
  };

  const endpoint = `${BASE_URL}concrete/stress/${parseParams(params)}`;
  window.open(endpoint, "_blank");
}

export function cpToBreakReport(
  startDate: string,
  endDate: string,
  application: string,
  token: string,
  controlId?: number,
) {
  const params = {
    token: token,
    application: application,
    start_date: startDate,
    end_date: endDate,
    control: controlId,
  };

  const endpoint = `${BASE_URL}concrete/remains/${parseParams(params)}`;
  window.open(endpoint, "_blank");
}

export function cpToBreakReportV2(
  startDate: string,
  endDate: string,
  application: string,
  token: string,
  controlId?: number,
) {
  const params = {
    token: token,
    application: application,
    start_date: startDate,
    end_date: endDate,
    control: controlId,
  };

  const endpoint = `${BASE_URL_V2}/stress-remains${parseParams(params)}`;
  window.open(endpoint, "_blank");
}

export function soilSampleReport(
  contractId: number,
  application: string,
  token: string,
  samples: string[] | number[],
  layers: string[],
  places: string[],
  cover: boolean,
  granulometry: boolean,
  limits: boolean,
  compaction: boolean,
  cbr: boolean,
  cbrCharts: boolean,
) {
  const params = {
    contract: contractId,
    samples: samples.join(","),
    token: token,
    application: application,
    layers,
    places,
    cover,
    granulometry,
    limits,
    compaction,
    cbr,
    cbr_charts: cbrCharts,
  };

  const endpoint = `${BASE_URL}soil_new/sample/${parseParams(params)}`;
  window.open(endpoint, "_blank");
}

export function sampleSummaryXlsxReport(
  contractId: number,
  sampleIds: number[],
) {
  const endpoint = `soil_samples/summary/${contractId}/xlsx/`;
  const params = {
    codes: sampleIds.join(","),
  };

  return api.get(endpoint, { params });
}

export function fullSoilReportV2(
  token: string,
  contractId: number,
  samples: number[],
  application: string,
  cover: boolean,
  granulometry: boolean,
  limits: boolean,
  compaction: boolean,
  cbr: boolean,
  cbr_charts: boolean,
) {
  const params = {
    token,
    contract: contractId,
    samples: samples.join(","),
    application,
    cover,
    granulometry,
    limits,
    compaction,
    cbr,
    cbr_charts,
  };

  const endpoint = `${BASE_URL_V2}/soil${parseParams(params)}`;
  window.open(endpoint, "_blank");
}

export function sampleSummaryReport(
  contractId: number,
  application: string,
  token: string,
  samples: string[] | number[],
  places: string[],
  layers: string[],
) {
  const params = {
    contract: contractId,
    samples: samples.join(","),
    token: token,
    application: application,
    places,
    layers,
  };

  const endpoint = `${BASE_URL}soil/summary/${parseParams(params)}`;
  window.open(endpoint, "_blank");
}

export function summarySoilReportV2(
  token: string,
  contractId: number,
  samples: number[],
  application: string,
) {
  const params = {
    token,
    contract: contractId,
    samples: samples.join(","),
    application,
  };

  const endpoint = `${BASE_URL_V2}/soil-summary${parseParams(params)}`;
  window.open(endpoint, "_blank");
}

export function insituHolesReport(
  contractId: number,
  application: string,
  token: string,
  places: string[],
  layers: string[],
  dates: string[],
) {
  const params = {
    contract: contractId,
    application,
    token,
    places: places,
    layers: layers,
    date: dates,
  };

  const endpoint = `${BASE_URL}insitu/holes/${parseParams(params, true)}`;
  window.open(endpoint, "_blank");
}

export function customInsituXlsx(
  insituTestId: number,
  reportId: number,
  places: string[],
  layers: string[],
  dates: string[],
) {
  const endpoint = `core/xlsx/custom_report/`;

  const params = {
    xlsx: reportId,
    insitu_pk: insituTestId,
    places: places.join(","),
    layers: layers.join(","),
    dates: dates.join(","),
  };

  return api.get<XlsxCustomReport>(endpoint, { params });
}

export function grauteTraceReport(
  contractId: number,
  application: string,
  token: string,
  towers: string[],
  joins: string[],
  links: string[],
  dateStart: string,
  dateEnd: string,
  providers: string[],
  collected: boolean,
) {
  const params = {
    application,
    token,
    contract: contractId,
    date_start: dateStart,
    date_end: dateEnd,
    providers: providers.join(","),
    towers: towers.join(","),
    joins: joins.join(","),
    links: links.join(","),
    collected,
  };

  const endpoint = `${BASE_URL}graute/traces/${parseParams(params)}`;
  window.open(endpoint, "_blank");
}

export function grauteSeriesReport(
  contractId: number,
  application: string,
  token: string,
  towers: string[],
  joins: string[],
) {
  const params = {
    application,
    token,
    contract: contractId,
    towers: towers.join(","),
    joins: joins.join(","),
  };

  const endpoint = `${BASE_URL}graute/series/${parseParams(params)}`;
  window.open(endpoint, "_blank");
}
export function grauteFlexionReport(
  contractId: number,
  application: string,
  token: string,
  towers: string[],
  joins: string[],
) {
  const params = {
    application,
    token,
    contract: contractId,
    towers: towers.join(","),
    joins: joins.join(","),
  };

  const endpoint = `${BASE_URL}graute/series_flex/${parseParams(params)}`;
  window.open(endpoint, "_blank");
}

export function concreteStressXlsx(
  controlId: number,
  startDate: string,
  endDate: string,
  places: string[],
  optionalPlaces: string[],
  series: string,
) {
  const endpoint = `/concretes/${controlId}/series/xlsx/`;

  const params = {
    places: places.join(","),
    optional_places: optionalPlaces.join(","),
    series: series,
    molding_start: startDate,
    molding_end: endDate,
  };

  return api.get(endpoint, { params });
}

export function concreteQuebecXlsx(
  controlId: number,
  startDate: string,
  endDate: string,
  places: string[],
  optionalPlaces: string[],
  series: string,
) {
  const endpoint = `/concretes/${controlId}/series/custom_xlsx/`;

  const params = {
    places: places.join(","),
    optional_places: optionalPlaces.join(","),
    series: series,
    molding_start: startDate,
    molding_end: endDate,
  };

  return api.get(endpoint, { params });
}

export function customConcreteXlsx(
  reportId: number,
  controlId: number,
  startDate: string,
  endDate: string,
  places: string[],
  optionalPlaces: string[],
  series: string,
) {
  const endpoint = `core/xlsx/custom_report/`;

  const params = {
    xlsx: reportId,
    control: controlId,
    places: places.join(","),
    optional_places: optionalPlaces.join(","),
    series: series,
    molding_start: startDate,
    molding_end: endDate,
  };

  return api.get(endpoint, { params });
}

export function invoiceReport(
  contractId: number,
  application: string,
  token: string,
  bill: number,
  cover: boolean,
  soil: boolean,
  concrete: boolean,
) {
  const params = {
    contract: contractId,
    application: application,
    token,
    bill,
    cover,
    soil,
    concrete,
  };
  const endpoint = `${BASE_URL}invoice/${parseParams(params)}`;

  window.open(endpoint, "_blank");
}

export function invoiceControlReport(
  application: string,
  token: string,
  client?: string,
  work?: string,
  startMonth?: string,
  startYear?: string,
  endMonth?: string,
  endYear?: string,
  subDepartments?: string,
  status?: string,
) {
  const params = {
    application,
    token,
    client,
    work,
    start_month: startMonth,
    start_year: startYear,
    end_month: endMonth,
    end_year: endYear,
    subDepartments,
    status,
  };
  const endpoint = `${BASE_URL}invoice/control/${parseParams(params)}`;

  window.open(endpoint, "_blank");
}

export function blockConcreteStressReport(
  application: string,
  token: string,
  contract: number,
  lots: number[],
) {
  const params = {
    application,
    token,
    contract,
    lots: lots.join(","),
  };
  const endpoint = `${BASE_URL}block_concrete/stress/${parseParams(params)}`;

  window.open(endpoint, "_blank");
}

export function appInvoiceReport(
  application: string,
  token: string,
  month: string,
  year: string,
) {
  const params = {
    application,
    token,
    year,
    month,
  };
  const endpoint = `${BASE_URL}core/invoice/${parseParams(params)}`;

  window.open(endpoint, "_blank");
}

export function densityV2(
  places: string[],
  layers: string[],
  date: string[],
  optional_places: string[],
  samples: number[],
  application: string,
  token: string,
  density: number,
  contract: number,
) {
  const params = {
    places,
    layers,
    dates: date,
    optional_places,
    samples,
    application,
    token,
    density,
    contract,
  };

  const endpoint = `${BASE_URL_V2}/density${parseParams(params)}`;
  window.open(endpoint, "_blank");
}

export function grauteTraceReportV2(
  controlId: number,
  application: string,
  token: string,
  towers: string[],
  joins: string[],
  links: string[],
  dateStart: string,
  dateEnd: string,
  providers: string[],
  collected: boolean,
) {
  const params = {
    application,
    token,
    control: controlId,
    date_start: dateStart,
    date_end: dateEnd,
    providers: providers.join(","),
    tower: towers.join(","),
    join: joins.join(","),
    link: links.join(","),
    collected,
  };

  const endpoint = `${BASE_URL_V2}/graute-traces${parseParams(params)}`;
  window.open(endpoint, "_blank");
}
